.show-schools {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  gap: 1em;
  margin: auto;
  margin-bottom: 3rem;
  min-width: 825px;
  min-height: 300px;
}

.show-schools-list {
  display: flex;
  row-gap: 20px;
  column-gap: 5vw;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  width: 90%;
}

.show-schools-list-card {
  max-height: 71px;
  margin-left: 50px;
  border-radius: 5px;
  height: auto;
  min-height: 50px;
  min-width: 215px;
  width: 25vw;
  margin: 0px;
  border: solid 1px #0d172b41;
  -webkit-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
}

.schools-list-logo {
  max-height: 10vh;
  height: 90%;
  padding: 0.25em;
  border-radius: 5px;
}
.add-school {
  border: 1px dashed #304050;
  justify-content: center;
  gap: 0.5rem;
  height: auto;
  background-color: #f0f0f0;
}
.add-school:hover {
  background-color: #e9f9fc;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.add-school-text {
  color: rgba(30, 30, 30, 0.8);
  font-weight: bold;
  font-size: 1em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: center;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.add-school:hover .add-school-text {
  font-size: 1.1em;
  transition: all 0.3s ease-in-out;
}
