.add-entry {
  z-index: 20;
  height: 100vh;
  width: 99vw;

  position: absolute;
  animation: drop-in 0.5s ease-in-out forwards;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
}
.add-entry-card {
  background-color: white;
  width: 66vw;
  padding: 1.25rem;
  min-width: 400px;
  max-width: 800px;
  border-radius: 5px;
  border: 3px rgb(112, 112, 112) solid;
  min-height: fit-content;
  flex-direction: column;
  gap: 0.25rem;
}
.add-entry-reverse {
  animation: drop-in-reverse 0.5s ease-in-out forwards;
}
.add-entry-card-container {
  height: fit-content;
  padding: 3rem 0;
  align-items: flex-start;
  justify-content: center;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes drop-in-reverse {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
}
