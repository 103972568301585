.add-attractObj-form-container {
  z-index: 20;
  height: 100vh;
  width: 99vw;

  position: absolute;
  animation: drop-in 0.5s ease-in-out forwards;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
}
.add-attractObj-card {
  background-color: white;
  width: 66vw;
  padding: 1.25rem;
  min-width: 400px;
  max-width: 800px;
  border-radius: 5px;
  border: 3px rgb(112, 112, 112) solid;
  min-height: fit-content;
  flex-direction: column;
  gap: 0.25rem;
}
.add-attractObj-form-container-closed {
  animation: drop-in-reverse 0.5s ease-in-out forwards;
}
.add-attractObj-card-container {
  height: fit-content;
  padding: 3rem 0;
  align-items: flex-start;
  justify-content: center;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes drop-in-reverse {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.background-color-div {
  flex-grow: 1;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
  border-radius: 5px;
}
.selected-box {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px, 0 0 0 3px white, 0 0 0 5px black;
}
.sketch-picker-container {
  flex-grow: 1;
}

.preview-attractObj-card {
  position: relative;
  border-radius: 5px;
  border: 3px rgb(112, 112, 112) solid;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: white;
}

.preview-attractObj-card-container {
  height: 100%;
  width: 100%;
  position: absolute;
}
.preview-add-attractObj-close-icon {
  top: -8px;
  right: -8px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  color: rgb(178 9 50);
  scale: 2;
  transition: all 0.3s ease-in-out;
  border: 2px solid rgb(178 9 50);
}

.preview-add-attractObj-close-icon:active {
  top: -2px;
}

.preview-attractObj-card-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.preview-attractObj-card-img {
  height: 100%;
  left: 10.4%;
}
.preview-attractObj-card-title {
  left: 6.25%;
  bottom: 27.5%;
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
  font-family: 'Franklin Gothic Demi';
  font-size: 5%;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;

  margin: 0;
}
.preview-attractObj-card-subtitle {
  left: 7.8%;
  bottom: 22%;
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
  font-family: 'Franklin Gothic Demi';

  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: capitalize;

  margin: 0;
}
.preview-attractObj-card-primary {
  height: 72.1%;
  width: 78.1%;
  left: 5.2%;
}
.preview-attractObj-card-secondary {
  height: 72.1%;
  width: 92.6%;
  bottom: 0;
}
.preview-example-container > * {
  position: absolute;
}
.preview-example-container {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  border-radius: 5px;
}
