.add-view {
  z-index: 20;
  height: 100vh;
  width: 99vw;

  position: absolute;
  animation: drop-in-view 0.5s ease-in-out forwards;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
}
.space-between {
  justify-content: space-between;
  border-bottom: 1px solid #304050;
}
.add-view-reverse {
  animation: drop-in-view-reverse 0.5s ease-in-out forwards;
}
@keyframes drop-in-view {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes drop-in-view-reverse {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.add-view-card-container {
  height: fit-content;
  padding: 3rem 0;
  align-items: flex-start;
  justify-content: center;
}
.add-view-card {
  background-color: white;
  width: 66vw;
  min-width: 400px;
  max-width: 800px;
  border-radius: 5px;
  border: 3px rgb(112, 112, 112) solid;
  min-height: fit-content;
  flex-direction: column;
  gap: 0.25rem;
}
.add-view-card-title {
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
}

.add-view-contents-1 {
  margin: 1rem 1rem 0 1rem;
  min-height: 25vh;
}
.add-view-contents-2 {
  flex-direction: column;
  margin: 1rem;
}
.notable-text::before {
  content: '🎖️';
  margin-right: 5px;
}
