.slick-slide {
  max-height: 30vh;
  margin-left: 50px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 154px 52px -94px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 154px 52px -94px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 154px 52px -94px rgba(0, 0, 0, 0.4);
}
.slick-slide img {
  max-height: 30vh;
  border-radius: 5px;
}
.slick-list {
  overflow: unset;
}
.amplify-heading--6 {
  margin-block-start: unset;
  margin-top: 1rem;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.5em;
  scrollbar-gutter: both;
}

::-webkit-scrollbar-track {
  background-color: #b0e3ed85;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background-color: #2b373a85;
  border-radius: 0.25rem;
}
@font-face {
  font-family: 'Franklin Gothic Book';
  src: url('./fonts/FranklinGothicBookRegular.ttf');
}
@font-face {
  font-family: 'Franklin Gothic Demi';
  src: url('./fonts/FranklinGothicDemiRegular.ttf');
}
