.show-attractObjs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  gap: 1em;
  margin: auto;
  margin-bottom: 3rem;
  min-width: 825px;
  min-height: 300px;
}

.show-attractObjs-list {
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  margin-bottom: 2rem;
  row-gap: 2rem;
  padding: 2rem;
  width: 100%;
  justify-content: center;
  justify-items: center;
}

.show-attractObjs-list-card {
  border-radius: 5px;
  height: 200px;

  width: 300px;
  position: relative;
  border: solid 1px #0d172b41;
  -webkit-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.17);
}

.delete-attractObj {
  top: -8px;
  right: -8px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  color: rgb(178 9 50);
  scale: 2;
  transition: all 0.3s ease-in-out;
}
.delete-attractObj:active {
  top: -2px;
}

.attractObjs-list-logo {
  max-height: 10vh;
  height: 90%;
  padding: 0.25em;
  border-radius: 5px;
}
.add-attractObj {
  display: flex;
  border: 1px dashed #304050;
  justify-content: center;
  gap: 0.5rem;
  background-color: #f0f0f0;
  align-items: center;
}
.add-attractObj:hover {
  background-color: #e9f9fc;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.add-attractObj-text {
  color: rgba(30, 30, 30, 0.8);
  font-weight: bold;
  font-size: 1em;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: center;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.add-attractObj:hover .add-attractObj-text {
  font-size: 1.1em;
  transition: all 0.3s ease-in-out;
}

.attract-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #f0f0f0;
  overflow: hidden;
}
.attract-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.attract-image-info {
  position: absolute;
  bottom: 0;
  padding: 0.5em;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.attract-image-info > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.edit-attractObj-pencil:hover {
  border: 2px solid #304050;
  cursor: pointer;
}
.edit-attractObj-pencil {
  border-radius: 3px;
}
