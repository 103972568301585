.image-viewer-container {
  width: 100%;
  height: 100%;
  margin: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-viewer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-thumbnails {
  width: 100%;
  height: 20%;
  box-sizing: border-box;
  gap: 4px;
  overflow-x: scroll;
}
.image-thumbnail {
  height: 100%;
  width: auto;
  border-radius: 3px;
}
.image-thumbnail-name {
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#imageModal {
  background-color: white;
  border: #707070 3px solid;
  width: 60vw;
  height: 75vh;
  border-radius: 0.5rem;
}
#imageModal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
#imageModalImage {
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
}
.image-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
